import './style.scss';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import PasswordCreationInput from '../../components/elements/formInputs/PasswordCreationInput';
import actionCreator from '../../actioncreators/accountSettings/changeUsername';
import actionTypes from '../../actiontypes';
import { getI18nContext } from '../TranslatedText';
import { UsernameRule } from '../../utils/validator/rules';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  TextInput,
  InlineMessage,
} from '../../components';
import SpinnerIcon from '../../../static/assets/images/static/spinner.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, StandardParagraph, ValidatedInput } from '@idm/ui-components';
import { Form } from '@tbiegner99/react-forms';
import React from 'react';

const I18nKeys = {
  usernameLabel: 'OBLIGATION.CHANGE_USERNAME.USERNAME_LABEL',
  singleIdTitle: 'OBLIGATION.CHANGE_USERNAME.SINGLE_ID.TITLE',
  multipleIdsTitle: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.TITLE',
  singleIdSubTitle: 'OBLIGATION.CHANGE_USERNAME.SINGLE_ID.SUBTITLE',
  multipleIdsSubTitleOne: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.SUBTITLE_PART_ONE',
  multipleIdsSubTitleTwo: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.SUBTITLE_PART_TWO',
  continueButton: 'OBLIGATION.CHANGE_USERNAME.CONTINUE_BUTTON',
  submitButton: 'OBLIGATION.CHANGE_USERNAME.SUBMIT_BUTTON',
  backButton: 'OBLIGATION.CHANGE_USERNAME.BACK_BUTTON',
  updateSuccess: 'OBLIGATION.CHANGE_USERNAME.UPDATE_SUCCESS',
  updateFailure: 'OBLIGATION.CHANGE_USERNAME.ERROR',
  currentUsername: 'OBLIGATION.CHANGE_USERNAME.CURRENT_USERNAME',
  textInputError: 'TEXT_INPUT.ERROR_MESSAGE',
  newPasswordLabel: 'OBLIGATION.CHANGE_USERNAME.PASSWORD_LABEL',
};

class ChangeUsernameObligationPage extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape({ getRawTextForKey: PropTypes.func.isRequired }).isRequired,
    dispatchOnSubmit: PropTypes.func.isRequired,
    associateUserNameDetails: PropTypes.array,
    isUsernameUnique: PropTypes.bool,
    error: PropTypes.object,
    isErrorVisible: PropTypes.bool,
    usernameInputs: PropTypes.array,
    username: PropTypes.string.isRequired,
    isLoadingConflictingUsernames: PropTypes.bool.isRequired,
    isLoadingCurrentUsername: PropTypes.bool.isRequired,
    isCheckingUsernameUnique: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    dispatchCheckUsernameUnique: PropTypes.func.isRequired,
    dispatchLoadData: PropTypes.func.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    dispatchResetError: PropTypes.func.isRequired,
    userNameRuleViolationMsg: PropTypes.string,
  };

  static defaultProps = {
    associateUserNameDetails: [],
    isUsernameUnique: true,
    usernameInputs: [],
    isErrorVisible: false,
    error: {
      code: undefined,
      message: undefined,
    },
    userNameRuleViolationMsg:
      'Usernames must be 5-40 characters and only contain letters, numbers, _, -, @, and non-consecutive periods.',
  };

  constructor(props) {
    super(props);
    this.inputs = [];
    this.state = {
      showEducationalMessage: true,
    };
    this.handleSubmit = this.submit.bind(this);
  }

  componentDidMount() {
    const { dispatchLoadData } = this.props;

    dispatchLoadData();
  }

  onContinue = () => {
    this.setState({ showEducationalMessage: false });
  };

  onCancel = () => {
    const { dispatchResetError } = this.props;

    dispatchResetError();
    this.setState({ showEducationalMessage: true });
  };

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  handleKeyPress = (event) => {
    const { dispatchSetFormIsValidating } = this.props;

    if (event.key === 'Enter') {
      dispatchSetFormIsValidating();
    }
  };

  checkUsernameConflict = async (inputUsername) => {
    const { usernameInputs, username } = this.props;
    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const isUserNameDuplicatedWithinUser =
      usernameInputsExcludingPassword &&
      Object.values(usernameInputsExcludingPassword)
        .map((i) => i.value)
        .filter((name) => name === inputUsername).length > 1;

    const isSameAsCurrentUsername =
      usernameInputsExcludingPassword &&
      Object.values(usernameInputsExcludingPassword)
        .map((i) => i.value)
        .filter((name) => name === username).length > 0;

    if (isUserNameDuplicatedWithinUser || isSameAsCurrentUsername) {
      throw Error;
    }
  };

  checkUsernameUnique = async (inputUsername) => {
    const { dispatchCheckUsernameUnique } = this.props;

    await dispatchCheckUsernameUnique(inputUsername);

    const { isUsernameUnique, isCheckingUsernameUnique } = this.props;

    if (!isCheckingUsernameUnique && !isUsernameUnique) {
      throw Error;
    }
  };

  getRules = (inputUsername, suggestedUsername) => {
    const { i18nContext, userNameRuleViolationMsg } = this.props;
    const rules = [
      new UsernameRule(userNameRuleViolationMsg),
      new ValidatedInput.CustomRule(
        this.checkUsernameConflict,
        i18nContext.getRawTextForKey('ERROR')
      ),
    ];

    if (suggestedUsername !== inputUsername) {
      rules.push(
        new ValidatedInput.CustomRule(
          this.checkUsernameUnique,
          i18nContext.getRawTextForKey('ERROR_USERNAME_UNAVAILABLE')
        )
      );
    }

    return rules;
  };

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleChange(field, event) {
    const { dispatchSetFormField, isErrorVisible, dispatchResetError } = this.props;

    if (isErrorVisible) {
      dispatchResetError();
    }
    dispatchSetFormField(field, event.target.value);
  }

  handlePasswordChange(field, value) {
    const { dispatchSetFormField, isErrorVisible, dispatchResetError } = this.props;

    if (isErrorVisible) {
      dispatchResetError();
    }
    dispatchSetFormField(field, value);
  }

  submit = async () => {
    const { usernameInputs, dispatchOnSubmit } = this.props;

    const assertPromises = this.inputs.map((input) => input.assertValid());

    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const mappedFormData = Object.entries(usernameInputsExcludingPassword).map(([field, input]) => {
      const [clientId, associateId] = field.split(':');

      return {
        clientId,
        associateId,
        username: input.value,
        password: usernameInputs[`${field}:password`].value,
      };
    });

    try {
      await Promise.all(assertPromises);
      dispatchOnSubmit({ associateUsernames: mappedFormData });
    } catch (err) {
      // no-op
    }
  };

  handleUsernameChange = (field) => this.handleChange.bind(this, field);

  handlePasswordChangeEvent = (field) => this.handlePasswordChange.bind(this, field);

  handleFieldValidationChange = (field) => this.handleValidationChange.bind(this, field);

  handleUsernameKeyPress = () => this.handleKeyPress.bind(this);

  setRef = (obj) => {
    this.inputs.push(obj);
  };

  renderUsernameInput() {
    const { usernameInputs, associateUserNameDetails, i18nContext, dispatchSetFormField } =
      this.props;

    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const ismultipleClients = Object.keys(usernameInputsExcludingPassword).length > 1;

    return Object.entries(usernameInputsExcludingPassword).map(([field, input]) => {
      const passwordKey = `${field}:password`;
      const [clientId, associateId] = field.split(':');
      const { clientName, userId } = associateUserNameDetails.find(
        (d) => d.clientId === clientId && d.associateId === associateId
      );

      return (
        <div className="usernameInput" key={`div-username-input-${field}`}>
          {ismultipleClients && (
            <div>
              <div className="clientNameTitle">{clientName}</div>
            </div>
          )}
          <div className="usernameBlock">
            <div className="lileft">
              <div key={`span-username-input-${field}`} className="suggestedIdLabel">
                Suggested User ID
              </div>
              <div className="suggestedUserId">{userId}</div>
            </div>
            <div className="copyButton">
              <SecondaryButton
                data-meta-id="CopyButton"
                onClick={() => dispatchSetFormField(field, userId)}
              >
                Select Suggested User ID
              </SecondaryButton>
            </div>
          </div>
          <div>
            <TextInput
              label={i18nContext.getRawTextForKey('USERNAME_LABEL')}
              type="text"
              className="input-label"
              key={field}
              value={input.value}
              id={field}
              name={field}
              onChange={this.handleUsernameChange(field)}
              handleValidationChange={this.handleFieldValidationChange(field)}
              validateOnBlur={true}
              additionalRules={this.getRules(input.value, userId)}
              setRef={this.setRef}
            />
            <PasswordCreationInput
              data-meta-id="password"
              data-msg-required={<TranslatedText i18nKey={I18nKeys.textInputError} />}
              showRequirements={true}
              key={passwordKey}
              id={passwordKey}
              value={usernameInputs[passwordKey].value}
              label={<TranslatedText i18nKey={I18nKeys.newPasswordLabel} />}
              name={passwordKey}
              autoComplete="new-password"
              onChange={this.handlePasswordChangeEvent(passwordKey)}
              onValidate={this.handleFieldValidationChange(passwordKey)}
              validateOnChange={true}
            />
          </div>
          <div className="darkLine" />
        </div>
      );
    });
  }

  renderFormButtons() {
    const { isSubmitting, usernameInputs } = this.props;
    const isInputEmpty = Object.values(usernameInputs)
      .map((input) => input.value.trim() === '')
      .includes(true);

    return (
      <div className="buttonRow">
        <SecondaryButton data-meta-id="Cancel" onClick={this.onCancel}>
          <TranslatedText i18nKey={I18nKeys.backButton} />
        </SecondaryButton>
        <PrimaryButton data-meta-id="Submit" type="submit" disabled={isSubmitting || isInputEmpty}>
          {isSubmitting ? (
            <div className="loader" data-meta-id="username-page-loader">
              <img src={SpinnerIcon} alt="Spinner Icon" />
            </div>
          ) : (
            <TranslatedText i18nKey={I18nKeys.submitButton} />
          )}
        </PrimaryButton>
      </div>
    );
  }

  renderUsernameForm() {
    return (
      <Form id="change-username-form" onSubmit={this.handleSubmit}>
        {this.renderUsernameInput()}
        {this.renderFormButtons()}
      </Form>
    );
  }

  render() {
    const {
      i18nContext,
      associateUserNameDetails,
      isLoadingConflictingUsernames,
      isLoadingCurrentUsername,
      isErrorVisible,
      error,
    } = this.props;

    const { showEducationalMessage } = this.state;
    const numberOfClients = associateUserNameDetails?.length;

    const isMultiAssociateUser = numberOfClients > 1;
    const singleClientName = associateUserNameDetails[0]
      ? associateUserNameDetails[0].clientName
      : '';

    if (isLoadingConflictingUsernames || isLoadingCurrentUsername) {
      return (
        <div className="loader" data-meta-id="username-page-loader">
          <img src={SpinnerIcon} alt="Spinner Icon" />
        </div>
      );
    }

    return (
      <div className="change-username">
        {isErrorVisible && <InlineMessage type="error">{error.message}</InlineMessage>}
        <header>
          <PageHeader className="titleHeader">
            <TranslatedText
              i18nKey={isMultiAssociateUser ? I18nKeys.multipleIdsTitle : I18nKeys.singleIdTitle}
            />
          </PageHeader>
          {showEducationalMessage && (
            <StandardParagraph className="educational-message-text">
              <TranslatedText
                i18nKey={
                  isMultiAssociateUser ? I18nKeys.multipleIdsSubTitleOne : I18nKeys.singleIdSubTitle
                }
              />
              {isMultiAssociateUser && <span>{numberOfClients} </span>}
              {isMultiAssociateUser && <TranslatedText i18nKey={I18nKeys.multipleIdsSubTitleTwo} />}
              <br />
            </StandardParagraph>
          )}
        </header>
        <main>
          {!showEducationalMessage && isMultiAssociateUser && (
            <div className="clientMainSubtitle">Enter a new User ID for each client:</div>
          )}
          {!showEducationalMessage && !isMultiAssociateUser && (
            <div className="clientMainSubtitle">Enter a new User ID for {singleClientName}:</div>
          )}
          {!showEducationalMessage &&
            associateUserNameDetails &&
            this.renderUsernameForm(i18nContext)}
        </main>
        <footer>
          {showEducationalMessage && (
            <ButtonContainer className="continueButton">
              <SecondaryButton
                data-meta-id="continue"
                onClick={this.onContinue}
                disabled={isErrorVisible}
              >
                <TranslatedText i18nKey={I18nKeys.continueButton} />
              </SecondaryButton>
            </ButtonContainer>
          )}
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { translation, accountSettingsChangeUsername } = state;
  const {
    associateUserNameDetails,
    isUsernameUnique,
    username,
    isLoadingConflictingUsernames,
    isLoadingCurrentUsername,
    isCheckingUsernameUnique,
    isSubmitting,
    isErrorVisible,
    error,
  } = accountSettingsChangeUsername.main;
  const { dictionary } = translation.main;
  const i18nContext = getI18nContext('OBLIGATION.CHANGE_USERNAME', dictionary);

  return {
    i18nContext,
    associateUserNameDetails,
    isUsernameUnique,
    username,
    isLoadingConflictingUsernames,
    isLoadingCurrentUsername,
    isCheckingUsernameUnique,
    isSubmitting,
    isErrorVisible,
    error,
    usernameInputs: accountSettingsChangeUsername.form,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async dispatchLoadData() {
      await dispatch(actionCreator.loadData());
      await dispatch(actionCreator.getConflictingUsernames());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    async dispatchCheckUsernameUnique(inputUsername) {
      await dispatch(actionCreator.checkUsernameUnique(inputUsername));
    },

    dispatchResetState() {
      dispatch(actionCreator.resetMainState());
    },

    dispatchResetError() {
      dispatch({
        type: actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.RESET_ERROR,
      });
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    async dispatchOnSubmit(data) {
      dispatch(actionCreator.setFormIsValidating());
      await dispatch(actionCreator.updateUsername(data));
    },
  };
}

export {
  mapDispatchToProps,
  ChangeUsernameObligationPage as UnwrappedChangeUsernameObligationPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeUsernameObligationPage);
